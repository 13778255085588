import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { AnalyticsComponent } from 'route/analytics'

import * as userv1 from '../proto/iam/v1/user_pb'

import { Actions as AuthActions } from '../store/auth/actions'
import * as organizationSelectors from '../store/iam/organization/reducer'
import { Actions as UserActions } from '../store/iam/user/actions'
import * as userSelectors from '../store/iam/user/reducer'
import { RootState } from '../store/reducer'

import { ContentLayout } from 'components/Layouts/ContentLayout'

import { Header } from './Header'
import './Main.css'
import { Navbar } from './Navbar'
import { Notifications } from './Notifications'
import { Sidebar } from './Sidebar'
import { Sider } from './Sider'

export const Main = () => {
  const dispatch = useDispatch()
  const flags = useFlags()
  const ldClient = useLDClient()

  const user = useSelector<RootState, userv1.User | undefined>((state) =>
    userSelectors.getCurrentUser(state),
  )
  const organization = useSelector((state: RootState) => organizationSelectors.getCurrentOrg(state))

  const [menuCollapsed, setMenuCollapsed] = useState(() => {
    // Check if there is a saved state in localStorage, otherwise default to false
    const savedState = localStorage.getItem('menuCollapsed')
    return savedState ? JSON.parse(savedState) : false
  })

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    const roles = user?.getRolesList() ?? []
    dispatch(UserActions.setRoles(roles))

    if (!user) {
      return
    }

    ldClient?.identify({
      kind: 'multi',
      user: {
        key: user.getEmail(),
        ...(organization && { organization: organization.getOrganization() }),
      },
    })

    const handleResize = () => setIsMobile(window.innerWidth < 768)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleCollapse = () => {
    const newState = !menuCollapsed
    // Save the new state to localStorage
    localStorage.setItem('menuCollapsed', JSON.stringify(newState))
    setMenuCollapsed(newState)
  }

  const handleLogout = () => {
    dispatch(AuthActions.logout())
  }

  const devEnv = process.env.NODE_ENV === 'development'
  return (
    <>
      <div className="app-wrapper">
        {isMobile ? (
          <div className="header" id="header">
            <AnalyticsComponent devEnv={devEnv} />
            <Notifications />
            <Navbar onLogout={handleLogout} roles={user?.getRolesList() ?? []} />
          </div>
        ) : (
          <>
            <div className="header" id="header">
              <AnalyticsComponent devEnv={devEnv} />
              <Notifications />

              <Header organization={organization} user={user} onLogout={handleLogout} />
            </div>
            <div className="sidebar" id="sider">
              {flags.sidebarRefactor ? (
                <Sidebar
                  collapsed={menuCollapsed}
                  onCollapse={handleCollapse}
                  roles={user?.getRolesList() ?? []}
                  onLogout={handleLogout}
                />
              ) : (
                <Sider
                  onCollapse={handleCollapse}
                  collapsed={menuCollapsed}
                  onLogout={handleLogout}
                  roles={user?.getRolesList() ?? []}
                />
              )}
            </div>
          </>
        )}

        <ContentLayout className="content">
          <div className="content--shadow--height">
            <Outlet />
          </div>
        </ContentLayout>
      </div>
    </>
  )
}
