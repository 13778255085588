import { Organization } from '../proto/iam/v1/organization_pb'
import { User } from '../proto/iam/v1/user_pb'

import {
  AllowedForAll,
  AllowedForAllExceptAdmin,
  AllowedForTransporterShipperOrganization,
} from 'helpers/rolePermissions'

/**
 * Routes for the application in alphabetical order. Use these instead of hardcoded string in the code
 */
const RouteNames = {
  Addressess: '/addresses',
  Admin: '/admin/:page?',
  AdminUsers: '/admin/users',
  AdminMonitor: '/admin/monitor',
  AdminSupplierManage: '/admin/suppliers/managed/:to?',
  AdminSupplierList: '/admin/suppliers/list',
  ArticeTypes: '/article-types',
  Booking: '/bookings/:ref',
  Bookings: '/bookings',
  BookingCreate: '/bookings/create',
  BookingEdit: '/bookings/edit/:ref',
  CallBack: '/callback',
  Contracts: '/contracts',
  ContractsCreate: '/contracts/create',
  ContractsEdit: '/contracts/edit/:ref',
  Costs: '/costs',
  Drafts: '/drafts',
  DraftsCreate: '/drafts/create',
  DraftsEdit: '/drafts/edit/:ref',
  DraftsRef: '/drafts/:ref',
  Home: '/home',
  Invoices: '/invoices',
  Loading: '/loading/:to?',
  Login: '/login',
  Logout: '/logout',
  Main: '',
  Orders: '/orders',
  OrdersCreate: '/orders/create',
  OrdersRef: '/orders/:ref/:page?',
  Products: '/products',
  Profatibility: '/profitability',
  Profile: '/profile',
  Privacy: '/privacy',
  Quotes: '/quotes',
  QuotesCreate: '/quotes/create',
  QuotesEdit: '/quotes/edit/:ref',
  QuotesRef: '/quotes/:ref',
  Reports: '/reports',
  ReportsBookingProgress: '/reports/booking-progress',
  ReportsEmission: '/reports/emissions',
  ReportsOrderProgress: '/reports/order-progress',
  ReportsShipmentMode: '/reports/shipment-mode',
  Setup: '/setup',
  Shipments: '/shipments',
  ShipmentsRef: '/shipments/:ref',
  Surcharges: '/surcharges',
} as const

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]
type RolePermissions = ((roles: User.Role[], organisation?: Organization) => boolean)[]

type ReuiqredRolesAndRouteMap = {
  requiredRoles?: User.Role[]
  icon?: JSX.Element
  displayName?: string
}
/**
 * This map dictates which roles are required to access a route.
 * The available links in the sidemenu are based on this map.
 */
const requiredRolesRouteMap: Record<RouteName, ReuiqredRolesAndRouteMap> = {
  [RouteNames.Addressess]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
    displayName: 'Addresses',
  },
  [RouteNames.Admin]: {
    requiredRoles: [User.Role.ADMIN, User.Role.MANAGER, User.Role.TRANSPORTER],
    displayName: 'Admin',
  },
  [RouteNames.AdminUsers]: {
    requiredRoles: [User.Role.MANAGER, User.Role.TRANSPORTER, User.Role.ADMIN],
  },
  [RouteNames.AdminMonitor]: {
    requiredRoles: [User.Role.MANAGER, User.Role.TRANSPORTER],
  },
  [RouteNames.AdminSupplierManage]: {
    requiredRoles: [User.Role.MANAGER, User.Role.TRANSPORTER, User.Role.ADMIN],
  },
  [RouteNames.AdminSupplierList]: {
    requiredRoles: [User.Role.MANAGER, User.Role.TRANSPORTER, User.Role.ADMIN],
  },
  [RouteNames.ArticeTypes]: {
    requiredRoles: [User.Role.TRANSPORTER, User.Role.FINANCE],
  },
  [RouteNames.Booking]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
  },
  [RouteNames.Bookings]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
  },
  [RouteNames.BookingCreate]: {
    requiredRoles: [...AllowedForTransporterShipperOrganization],
  },
  [RouteNames.BookingEdit]: {
    requiredRoles: [User.Role.TRANSPORTER],
  },
  [RouteNames.CallBack]: {},
  [RouteNames.Contracts]: {
    requiredRoles: [User.Role.SALES, User.Role.FINANCE, User.Role.MANAGER, User.Role.TRANSPORTER],
  },
  [RouteNames.ContractsCreate]: {
    requiredRoles: [User.Role.SALES],
  },
  [RouteNames.ContractsEdit]: {
    requiredRoles: [User.Role.SALES],
  },
  [RouteNames.Costs]: { requiredRoles: [User.Role.TRANSPORTER, User.Role.FINANCE] },
  [RouteNames.Drafts]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
  },
  [RouteNames.DraftsCreate]: {
    requiredRoles: [...AllowedForTransporterShipperOrganization],
  },
  [RouteNames.DraftsEdit]: {
    requiredRoles: [...AllowedForTransporterShipperOrganization],
  },
  [RouteNames.DraftsRef]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
  },

  [RouteNames.Home]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
  },
  [RouteNames.Invoices]: {
    requiredRoles: [User.Role.ORGANIZATION, User.Role.TRANSPORTER, User.Role.FINANCE],
  },
  [RouteNames.Loading]: {},
  [RouteNames.Login]: {},
  [RouteNames.Logout]: {},
  [RouteNames.Main]: { requiredRoles: [...AllowedForAllExceptAdmin] },
  [RouteNames.Orders]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
  },
  [RouteNames.OrdersCreate]: {
    requiredRoles: [...AllowedForTransporterShipperOrganization],
  },
  [RouteNames.OrdersRef]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
  },
  [RouteNames.Products]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
  },
  [RouteNames.Profatibility]: {
    requiredRoles: [User.Role.TRANSPORTER, User.Role.FINANCE],
  },
  [RouteNames.Profile]: {
    requiredRoles: [...AllowedForAll],
  },
  [RouteNames.Privacy]: {
    requiredRoles: [...AllowedForAll],
  },
  [RouteNames.Quotes]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
  },
  [RouteNames.QuotesCreate]: {
    requiredRoles: [...AllowedForTransporterShipperOrganization],
  },
  [RouteNames.QuotesEdit]: {
    requiredRoles: [...AllowedForTransporterShipperOrganization],
  },
  [RouteNames.QuotesRef]: {
    requiredRoles: [...AllowedForAllExceptAdmin],
  },
  [RouteNames.Reports]: {
    requiredRoles: [User.Role.TRANSPORTER, User.Role.ORGANIZATION],
  },
  [RouteNames.ReportsBookingProgress]: {
    requiredRoles: [User.Role.TRANSPORTER, User.Role.ORGANIZATION],
  },
  [RouteNames.ReportsEmission]: {
    requiredRoles: [User.Role.ORGANIZATION, User.Role.TRANSPORTER],
  },
  [RouteNames.ReportsOrderProgress]: {
    requiredRoles: [User.Role.TRANSPORTER, User.Role.ORGANIZATION],
  },
  [RouteNames.ReportsShipmentMode]: { requiredRoles: [User.Role.TRANSPORTER] },
  [RouteNames.Setup]: {},
  [RouteNames.Shipments]: {
    requiredRoles: [User.Role.TRANSPORTER],
  },
  [RouteNames.ShipmentsRef]: {
    requiredRoles: [User.Role.TRANSPORTER],
  },
  [RouteNames.Surcharges]: {
    requiredRoles: [User.Role.SALES, User.Role.TRANSPORTER],
  },
}

const getDefaultRoutePerRole = (roles: User.Role[]): RouteName => {
  const roleToRouteMap: Record<Partial<User.Role>, RouteName> = {
    [User.Role.CONTROLLER]: RouteNames.Orders,
    [User.Role.RECEIVER]: RouteNames.Orders,
    [User.Role.SHIPPER]: RouteNames.Orders,
    [User.Role.ADMIN]: RouteNames.AdminUsers,
    [User.Role.TRANSPORTER]: RouteNames.Bookings,
    [User.Role.ORGANIZATION]: RouteNames.Bookings,
    [User.Role.FINANCE]: RouteNames.Bookings,
    [User.Role.MANAGER]: RouteNames.Bookings,
    [User.Role.SALES]: RouteNames.Bookings,
    [User.Role.API]: RouteNames.Bookings,
  }

  return roleToRouteMap[roles[0]] || RouteNames.Home
}

const getAllAllowedRoutes = (roles: User.Role[], excludeRoutes: RouteName[]) => {
  return Object.entries(requiredRolesRouteMap).filter((value) => {
    const hasRoles = roles.filter((role) => {
      return (
        value[1]?.requiredRoles?.includes(role) && !excludeRoutes.includes(value[0] as RouteName)
      )
    })
    return hasRoles?.length > 0
  })
}

export { RouteNames, requiredRolesRouteMap, getAllAllowedRoutes, getDefaultRoutePerRole }
export type { RolePermissions }
